
import { AxiosResponse } from 'axios';
import { Ref, provide, defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MitarbeiterForm from './MitarbeiterForm.vue';
import GradeGrid from './GradeGrid.vue';

export default defineComponent({
  components: {
    MitarbeiterForm,
    GradeGrid,
  },

  setup(props) {
    const store = useStore();
    const person = ref({ nameFirst: '', nameLast: '', role: 0, login: '', email: '', location: '', team: '', password: '', avatar: '', absprachen: '' });
    const router = useRouter();
    const id = computed(() => router.currentRoute.value.params.id.toString());
    const saving = ref(false);

    const roleName = (role: number) => store.getters['getRoleName'](role);

    store.dispatch('API', { method: 'get', url: `/user/${id.value}` }).then((res: AxiosResponse) => {
      res.data.rolename = roleName(res.data.role);
      person.value = res.data;
    });

    provide('onDelete', () => {
      store.dispatch('API', { method: 'delete', url: '/manage/user', data: { id: id.value } }).then((res) => {
        store.dispatch('addNotification', {
          type: 'success',
          lifetime: 4000,
          message: 'Mitarbeiter gelöscht',
        });
        router.push({ name: 'Mitarbeiter verwalten' });
      });
    });

    provide('onSave', (person: Ref<User>) => {
      saving.value = true;
      const currentuserid = store.getters.getUserID;
      if (person.value.absprachen && id.value == currentuserid) store.dispatch('setAbsprachen', person.value.absprachen);
      const formData = new FormData();
      formData.append('id', id.value.toString());
      formData.append('nameFirst', person.value.nameFirst);
      formData.append('nameLast', person.value.nameLast);
      formData.append('role', person.value.role.toString());
      if (person.value.position) formData.append('position', person.value.position);
      formData.append('login', person.value.login);
      formData.append('email', person.value.email);
      formData.append('location', person.value.location);
      formData.append('team', person.value.team);
      if (person.value.absprachen) formData.append('absprachen', person.value.absprachen);
      formData.append('invisible', person.value.invisible.toString());
      if (person.value.password) formData.append('password', person.value.password);
      if (typeof person.value.avatar !== 'string') formData.append('avatar', person.value.avatar || '');

      store.dispatch('API', { method: 'post', url: '/manage/user', data: formData }).then(() => {
        saving.value = false;
        store.dispatch('addNotification', {
          type: 'success',
          lifetime: 4000,
          message: 'Mitarbeiter gespeichert',
        });
      });
    });

    return {
      person,
      saving,
    };
  },
});
