<style lang="scss">
.form {
  .label {
    font-size: 12px;
    font-weight: bold;
  }
  button {
    padding:10px 15px !important;
  }

  input[type='number'] {
    width: 50px;
    text-align:center;
  }
  .display-normal {
  
    margin-bottom:10px;
  }
  .skill {
    display: inline-block;
    padding: 15px;
    justify-content: center;
    h3 {
      font-family: 'HelveticaNeueLTPro-Roman';

      padding-bottom: 10px;
    }

    .w-flex {
      .w-input {
        .w-input__input-wrap {
          background: #fff;
          border-radius: 0;
          text-align: center;
          height: auto;
          border-bottom: 0px solid #fff;
          .w-input__label {
            color: #333;
          }
          .w-input__input {
            height: auto;
            padding: 10px;
            background: #f9f9f9;
            border: 2px solid #fff;
            color: #333;
            margin-top: 10px;
            &:focus {
              background: #f1f1f1;
            }
          }
          &:after {
            border-bottom: 2px solid $c-smd-lachs;
          }
        }
      }
      .w-button {
        padding: 8px 15px;
        position: relative;
        top: -2px;
        left: 1px;
        font-size: 90%;
        height: auto;
        background: $c-smd-blue;
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="form">
    <h3>Skills</h3>
    <div class="row gy-1">
      <div class="col-lg-6 col-md-6 col-sm-12" :key="index" v-for="(skill, index) in grades">
        <div class="row align-end justify-center align-items-center display-normal">
          <div class="col-3">
            {{ skill.skill.name }}
          </div>
          <div class="col-3">
            <div class="row align-items-center">
              <div class="col-6 label">{{ skill.skill.dim1 }}</div>
              <div class="col-6"><input max="3" min="0" type="number" v-model="skill.grades.dim1" /></div>
            </div>
          </div>
          <div class="col-3">
            <div class="row gy-1 align-items-center">
              <div class="col-6 label">{{ skill.skill.dim2 }}</div>
              <div class="col-6"><input max="3" min="0" type="number" v-model="skill.grades.dim2" /></div>
            </div>
          </div>
          <div class="col-3"><button @click="onButtonClick(skill.skill._id)">Bestätigen</button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const grades = ref([]);
    const id = router.currentRoute.value.params.id;

    store.dispatch('fetchSkillsForUser', id).then((res) => {
      grades.value = res.skills
        .map((item: any) => {
          item.grades.dim1 = item.grades.dim1.toString();
          item.grades.dim2 = item.grades.dim2.toString();
          return item;
        })
        .sort((a: any, b: any) => {
          if (a.skill.name > b.skill.name) return 1;
          if (a.skill.name < b.skill.name) return -1;
          return 0;
        });
    });

    const onButtonClick = (skillid: string) => {
      // @ts-ignore
      store.dispatch('updateSkill', { id, skillid, grade: grades.value.find((item: any) => item.skill._id == skillid).grades, reason: 'Manual' }).then(() => {
        store.dispatch('addNotification', {
          type: 'success',
          lifetime: 4000,
          message: 'Änderung gespeichert',
        });
      });
    };

    return {
      grades,
      onButtonClick,
    };
  },
});
</script>
