
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const grades = ref([]);
    const id = router.currentRoute.value.params.id;

    store.dispatch('fetchSkillsForUser', id).then((res) => {
      grades.value = res.skills
        .map((item: any) => {
          item.grades.dim1 = item.grades.dim1.toString();
          item.grades.dim2 = item.grades.dim2.toString();
          return item;
        })
        .sort((a: any, b: any) => {
          if (a.skill.name > b.skill.name) return 1;
          if (a.skill.name < b.skill.name) return -1;
          return 0;
        });
    });

    const onButtonClick = (skillid: string) => {
      // @ts-ignore
      store.dispatch('updateSkill', { id, skillid, grade: grades.value.find((item: any) => item.skill._id == skillid).grades, reason: 'Manual' }).then(() => {
        store.dispatch('addNotification', {
          type: 'success',
          lifetime: 4000,
          message: 'Änderung gespeichert',
        });
      });
    };

    return {
      grades,
      onButtonClick,
    };
  },
});
